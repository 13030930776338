<template>
  <div class="page-containter">
    <main class="page">
      <div class="no-mobile intro">
        <section class="hello" style="transform: matrix(1, 0, 0, 1, 0, 0)">
          <div
            class="page-color d-flex align-items-center justify-content-center p-3"
          >
            <img src="../assets/img/logo-black.svg" alt="logo" />
          </div>
        </section>
        <section
          class="preambule d-flex flex-column justify-content-center align-items-stretch p-3"
          style="
            transform: matrix(1, 0, 0, 1, 0, 0);
            color: white;
            font-size: 5rem;
          "
        >
          <span class="p-5">
            <div class="ml-4" style="font-size: 9rem; font-weight: 600">
              Hello
            </div>
            <div class="ml-5">I am Rado :)</div>
          </span>
          <span class="">
            <div
              class="future-visit"
              style="display: none; align-items: end; justify-content: flex-end"
            >
              <div class="col-6" style="font-size: 5rem !important">
                Content will be added ASAP
                <p style="font-size: 2rem !important">
                  Looking forward to your future visit!
                </p>
              </div>
            </div>
          </span>
        </section>
      </div>
      <div class="mobile-content p-4">
        <img src="../assets/img/logo-white.svg" alt="logo" style="width: 75%" />
        <p style="color: white; font-size: 3rem">Content will be added :)</p>
      </div>
      <div class="video-container"></div>
    </main>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  name: "Introduction",
  props: {
    msg: String,
  },
  mounted: function () {
    $("body").addClass("showHello");

    // var tlPreambule;
    // tlPreambule= new TimelineLite({ paused: true});
    let scrollAmount;
    $(window).on("scroll", function () {
      if (!$("body").hasClass("page-home")) return;

      scrollAmount = $(window).scrollTop();
      var wW = $(window).width();

      //ANIMATION PREAMBULE
      if (scrollAmount > 10 && $("body").hasClass("showHello")) {
        $("body").removeClass("showHello");
        $(".future-visit").css("display", "flex");
        //tlPreambule.play().timeScale(1);
      } else if (scrollAmount <= 10 && !$("body").hasClass("showHello")) {
        $("body").addClass("showHello");
        $(".future-visit").css("display", "none");
        //tlPreambule.reverse().timeScale(2);
      }

      if (!$("body").hasClass("page-home") || wW < 768) {
        return;
      }
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
